@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
}


@font-face {
  font-family: customFont;
  src: url('assets/font/SST\ Arabic\ Roman.ttf');
}



* {
  font-family: customFont !important;
}

.leaflet-container {
  width: 100% !important;
  max-height: 100%;
}

/* width */
::-webkit-scrollbar {
  height: 0px;
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
  border-radius: 10px;
}